import React from "react"

const TakeAPeek = () => {
  return (
    <p>
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
      TAKE A PEEK{" "}
      <span role="img" aria-label="eyes emoji">
        👀
      </span>{" "}
    </p>
  )
}

export default TakeAPeek
